
<template>
  <div id="conteiner">
    <v-row no-gutters>
      <div id="side_menu">
        <div id="logo"><a href="#"><img src="@/assets/logo.png" width="432" height="162"> </a></div>
        <FormLogin v-if="formLoginComp" @openRegister="formLoginComp = false" />
        <FormRegister v-if="!formLoginComp" @openLogin="formLoginComp = true" />
      </div>
      <!-- <div id="content"></div> -->
      <div id="content">
        <iframe
          src="https://avraham.clickcall.co.il/img_login/display.html"
          frameborder="0"
          allowfullscreen
          style="width: 100% !important; height: 100% !important; border: none;"
        ></iframe>
      </div>
    </v-row>
  </div>
</template>

<script>
import FormLogin from '@/components/auth/FormLogin.vue'
import FormRegister from '@/components/auth/FormRegister.vue'
import Auth from '@/services/auth.service.js'

export default {
  components: {
    FormLogin,
    FormRegister
  },
  data: () => ({
    formLoginComp: true,
  }),
  mounted() {
    document.title = 'כניסה | clickcall'

    let auth = Auth.checkAuth();
    if (auth) {
      this.$router.replace({ name: "home" })
    }
  },
}
</script>
<style scoped>
#conteiner {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  height: 100vh;
}



#side_menu {
  background: #0d2c6d;
  width: 34%;
  float: right;
  height: 100vh;
  font-size: .8vw;
}

#content {
  /* background-image: url('@/assets/Artboard.png'); */
  width: 66%;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  background-color: #f7f7f7;
}

#logo {
  text-align: center;
  margin: 10% 0 0 0;
}

#logo img {
  width: 61%;
  margin-right: 4%;
  height: 100%;
}


@media only screen and (max-width: 998px) and (min-height: 600px) {
  #content {
    display: none !important;
  }

  #side_menu {
    width: 100%;
    font-size: 3vw;
  }


}


.notification {
  display: block;
  padding: 3% 13% 3% 5%;
  width: 76%;
  border-radius: .4vw;
  margin-bottom: 2vh;
}

/* hebrew */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/heebo/v22/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysd0mm_00.woff2) format('woff2');
  unicode-range: U+0590-05FF, U+200C-2010, U+20AA, U+25CC, U+FB1D-FB4F;
}

/* latin */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/heebo/v22/NGSpv5_NC0k9P_v6ZUCbLRAHxK1EiSysdUmm.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
</style>
