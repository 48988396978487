<template>
    <v-dialog v-model="openMode" max-width="600px">
        <v-card>
            <v-card-title>
                <span class="text-h5">העלאת הקלטות</span>
            </v-card-title>

            <v-card-text>
                <!-- Send Calls Checkbox -->
                <v-checkbox style="margin-bottom: 10px;" v-model="sendCalls" hide-details
                    :label="`שלח צינתוק לאחר סיום העלאה`"></v-checkbox>

                <!-- Allow Multiple Uploads Checkbox -->
                <v-checkbox style="margin-bottom: 10px;" v-model="allowMultiple" hide-details
                    :label="`אפשר העלאת מספר קבצים בו זמנית`"></v-checkbox>

                <!-- FilePond Component with Reordering -->
               <!-- FilePond Component with Reordering and Retry Disabled and Credits Hidden -->
                <file-pond
                    ref="pond"
                    name="record"
                    class="my-pond"
                    allow-multiple="true"
                    allow-reorder="true"
                    accepted-file-types="audio/*,video/mp4"
                    max-file-size="100MB"
                    :instantUpload="false"
                    :maxParallelUploads="allowMultiple ? 5 : 1"
                    :allowRevert="false"
                    :credits="false"  
                    @updatefiles="handleFileOrder"
                    @processfile="handleUploadComplete"
                    :server="serverOptions"
                    :label-idle="`גרור ושחרר קבצים לכאן או <span class='filepond--label-action'>בחר קבצים</span>`"
                    :label-invalid-field="'השדה מכיל ערכים לא חוקיים'"
                    :label-file-processing="'מעלה...'"
                    :label-file-processing-complete="'הקובץ הועלה בהצלחה!'"
                    :label-file-remove="'הסר'"
                     :label-tap-to-retry="'הקש כדי לנסות שוב'"
                     />
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red" text @click="openMode = false">ביטול</v-btn>
                <v-btn color="primary" text @click="submitAllFiles">העלה קבצים</v-btn>
            </v-card-actions>
        </v-card>

        <SnackBar v-model="snackbar" :snacbarText="snacbarText" :snackbarColorBt="snackbarColorBt"></SnackBar>
    </v-dialog>
</template>

<script>
import vueFilePond from 'vue-filepond';
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import SnackBar from '@/components/widgets/snackBar.vue';

const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);

export default {
    props: {
        value: { type: Boolean, default: false },
    },
    components: {
        SnackBar,
        FilePond
    },
    data() {
        return {
            serverOptions: {
                process: {
                    url: `${process.env.VUE_APP_BASE_URL}/records/add_record`,
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`
                    },
                    ondata: (formData) => {

                        this.filesStartUploadedCount++;
                        if (this.filesStartUploadedCount === this.files.length) {
                            formData.append('sendCalls', this.sendCalls);
                        }
                        return formData;
                    }
                }
            },
            sendCalls: false,
            snackbar: false,
            allowMultiple: false,
            snackbarColorBt: "green",
            snacbarText: "",
            files: [],
            filesStartUploadedCount: 0,
            filesEndtUploadedCount: 0
        };
    },
    methods: {
        handleFileOrder(files) {
            this.files = files.map(fileItem => fileItem.file);
        },

        async submitAllFiles() {
            if (this.files.length === 0) {
                this.showSnackBar('לא נבחרו קבצים', 'red');
                return;
            }

            this.filesStartUploadedCount = 0;
            this.filesEndtUploadedCount = 0;
            try {
                await this.$refs.pond.processFiles();
                this.showSnackBar('הקבצים הועלו בהצלחה', 'green');
            } catch (error) {
                this.showSnackBar('שגיאה בהעלאה', 'red');
            }
        },

        handleUploadComplete() {

            this.filesEndtUploadedCount++

            if (this.filesEndtUploadedCount === this.files.length) {
                console.log(222222);
                this.showSnackBar('הפעולה הסתיימה בהצלחה!', 'green');
                this.$emit('uploadComplete');
            }
        },

        showSnackBar(message, color) {
            this.snacbarText = message;
            this.snackbarColorBt = color;
            this.snackbar = true;
        }
    },

    computed: {
        openMode: {
            get() {
                return this.value;
            },
            set(v) {
                return this.$emit('input', v);
            }
        },
    },
}
</script>

<style scoped>
.my-pond {
    border: 2px dashed #3F51B5;
    border-radius: 10px;
    padding: 10px 10px 0px 10px;
    text-align: center;
    transition: background 0.3s;
}

.my-pond:hover {
    background: #f0f0f0;
    cursor: pointer;
}

.v-card-title {
    font-weight: bold;
}

</style>
